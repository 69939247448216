import { AddressFieldProto } from '@frontend2/proto/librarian/proto/entity_fields_pb';

export function isGhostAddressFieldProto(address: AddressFieldProto): boolean {
  return address.equals(new AddressFieldProto());
}

export function addressFielProtoToReadableString(
  address: AddressFieldProto,
): string {
  const nonEmptyJoin = (arr: string[]): string =>
    arr.filter((str) => str.trim() !== '').join(', ');

  const name = nonEmptyJoin([address.fullName, address.company]);

  const addr = nonEmptyJoin([address.address, address.address2]);

  const cityAddr = nonEmptyJoin([
    address.postalCode,
    address.province,
    address.city,
    address.country,
  ]);

  return nonEmptyJoin([name, addr, cityAddr]);
}
