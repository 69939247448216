import { InfluencerStatus } from '@frontend2/proto/common/proto/campaign_pb';
import { AddressFieldProto } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { LandingPagePb } from '@frontend2/proto/librarian/proto/landing_pages_pb';
import { CollaborationDetailsResponse } from '@frontend2/proto/librarian/proto/registered_author_pb';
import {
  ProductVariantQuantityPb,
  RegisteredAuthorProductRequestPb,
  RegisteredAuthorProductRequestStatus,
} from '@frontend2/proto/librarian/proto/registered_author_product_requests_pb';
import { isNil, isNotEmptyArray, isNotNil } from '../../utils/common.helpers';
import { isGhostLandingPb } from './landing_page_pb.helpers';

export function isGhostRegisteredAuthorProductRequest(
  item: RegisteredAuthorProductRequestPb,
): boolean {
  return item.requestId === BigInt(0);
}

export function readableRegisteredAuthorProductRequestStatus(
  status: RegisteredAuthorProductRequestStatus,
): string {
  switch (status) {
    case RegisteredAuthorProductRequestStatus.PENDING:
      return $localize`Pending`;
    case RegisteredAuthorProductRequestStatus.APPROVED:
      return $localize`Approved`;
    case RegisteredAuthorProductRequestStatus.REJECTED:
      return $localize`Rejected`;
    case RegisteredAuthorProductRequestStatus.SENT:
      return $localize`Sent`;
    default:
      return 'unknown';
  }
}

export function ProductVariantQuantityPbHasNotEnoughStock(
  product: ProductVariantQuantityPb,
): boolean {
  return product.quantity > (product.productVariant?.quantity ?? 0);
}

export function getCollaborationProductRequestAddress(
  collaboration: CollaborationDetailsResponse,
): AddressFieldProto {
  return collaboration.influencerRequest?.address ?? new AddressFieldProto();
}

export function isGhostCollaboration(
  collaboration?: CollaborationDetailsResponse,
): boolean {
  return isNil(collaboration) || isGhostLandingPb(collaboration.pageDetails);
}

export function isCollaborationProductRequestValid(
  collaboration: CollaborationDetailsResponse,
): boolean {
  return (
    isNotNil(collaboration.influencerRequest?.address) &&
    isNotEmptyArray(collaboration.influencerRequest?.productsVariants) &&
    collaboration.influencerRequest.status !==
      RegisteredAuthorProductRequestStatus.TO_COMPLETE
  );
}

function _influencerHasAcceptedCollaboration(
  collaboration: CollaborationDetailsResponse,
): boolean {
  return (
    collaboration.influencerStatus === InfluencerStatus.INFLUENCER_ACCEPTED
  );
}

function _influencerHasSkippedProductsStep(
  collaboration: CollaborationDetailsResponse,
  page: LandingPagePb,
): boolean {
  return (
    page.requireProductSeeding === true &&
    isCollaborationProductRequestValid(collaboration) === false
  );
}

export function isCollaborationValidAndConfirmed(
  collaboration: CollaborationDetailsResponse,
  page: LandingPagePb,
): boolean {
  return (
    _influencerHasAcceptedCollaboration(collaboration) === true &&
    // when we skip other step, we don't come back to the onbording flow to fix them
    // but it's different with the products step, the only solution to fix it is to start the onboarding flow again
    _influencerHasSkippedProductsStep(collaboration, page) === false
  );
}
